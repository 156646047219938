// src/features/gallery/gallerySlice.ts

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentUser } from 'aws-amplify/auth';
import { getGalleryImages } from '../../../utils/s3Get';
import type { RootState } from '../../index';

export interface Photo {
  key: string;
  url: string;
}

interface GalleryState {
  photos: Photo[];
  loading: boolean;
  error: string | null;
  selectedPhotoKey: string | null;
  deleteInProgress: boolean;
  downloadInProgress: boolean;
}

const initialState: GalleryState = {
  photos: [],
  loading: false,
  error: null,
  selectedPhotoKey: null,
  deleteInProgress: false,
  downloadInProgress: false,
};

export const fetchPhotos = createAsyncThunk(
  'gallery/fetchPhotos',
  async (_, { rejectWithValue }) => {
    try {
      const { userId: sub } = await getCurrentUser();
      const result = await getGalleryImages(sub);
      return result?.files || [];
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'Failed to fetch photos');
    }
  }
);

export const downloadPhoto = createAsyncThunk(
  'gallery/downloadPhoto',
  async (photo: Photo, { rejectWithValue }) => {
    try {
      const response = await fetch(photo.url, { mode: 'cors' });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = photo.key.split('/').pop() || 'photo.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      return photo.key;
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'Failed to download photo');
    }
  }
);

// TODO: Implement when backend is ready
export const deletePhoto = createAsyncThunk(
  'gallery/deletePhoto',
  async (photo: Photo, { rejectWithValue }) => {
    try {
      // Add your delete API call here
      return photo.key;
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'Failed to delete photo');
    }
  }
);

const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    setSelectedPhoto: (state, action) => {
      state.selectedPhotoKey = action.payload;
    },
    clearSelectedPhoto: (state) => {
      state.selectedPhotoKey = null;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Photos
      .addCase(fetchPhotos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPhotos.fulfilled, (state, action) => {
        state.loading = false;
        state.photos = action.payload;
      })
      .addCase(fetchPhotos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string || 'Failed to fetch photos';
      })
      // Download Photo
      .addCase(downloadPhoto.pending, (state) => {
        state.downloadInProgress = true;
      })
      .addCase(downloadPhoto.fulfilled, (state) => {
        state.downloadInProgress = false;
      })
      .addCase(downloadPhoto.rejected, (state, action) => {
        state.downloadInProgress = false;
        state.error = action.payload as string || 'Failed to download photo';
      })
      // Delete Photo
      .addCase(deletePhoto.pending, (state) => {
        state.deleteInProgress = true;
      })
      .addCase(deletePhoto.fulfilled, (state, action) => {
        state.deleteInProgress = false;
        state.photos = state.photos.filter(photo => photo.key !== action.payload);
        if (state.selectedPhotoKey === action.payload) {
          state.selectedPhotoKey = null;
        }
      })
      .addCase(deletePhoto.rejected, (state, action) => {
        state.deleteInProgress = false;
        state.error = action.payload as string || 'Failed to delete photo';
      });
  },
});

// Selectors
export const selectGalleryState = (state: RootState) => state.gallery;
export const selectPhotos = (state: RootState) => state.gallery.photos;
export const selectLoading = (state: RootState) => state.gallery.loading;
export const selectError = (state: RootState) => state.gallery.error;
export const selectSelectedPhoto = (state: RootState) => {
  const selectedKey = state.gallery.selectedPhotoKey;
  return selectedKey ? state.gallery.photos.find(p => p.key === selectedKey) || null : null;
};
export const selectDeleteInProgress = (state: RootState) => state.gallery.deleteInProgress;
export const selectDownloadInProgress = (state: RootState) => state.gallery.downloadInProgress;

export const { setSelectedPhoto, clearSelectedPhoto, clearError } = gallerySlice.actions;
export default gallerySlice.reducer;
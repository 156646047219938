import React from 'react';
import { FaLinkedin, FaInstagram } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useAppSelector } from '../../hooks/redux';
import { 
  selectTopTeamMembers, 
  selectBottomTeamMembers,
  selectTeamLoading,
  selectTeamError,
  TopTeamMember,
  BottomTeamMember
} from '../../store/features/ourTeam/teamSlice';

const WhoAreWePage: React.FC = () => {
  const topTeamMembers = useAppSelector(selectTopTeamMembers);
  const bottomTeamMembers = useAppSelector(selectBottomTeamMembers);
  const loading = useAppSelector(selectTeamLoading);
  const error = useAppSelector(selectTeamError);

  if (loading) {
    return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="flex justify-center items-center min-h-screen text-red-500">{error || 'An error occurred'}</div>;
  }

  return (
    <div className="py-16 bg-white text-[#004948]">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2 
          className="text-4xl sm:text-5xl font-bold mb-4 text-center text-[#084248]"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
        >
          Our Team
        </motion.h2>
        
        <motion.p 
          className="text-lg mb-12 text-black text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          style={{ fontFamily: '"Font1", sans-serif' }}
        >
          We are dreamers, thinkers, and tinkerers at the forefront of ethical AI in visual media. 
          Our team is driven by a passion for innovation and a commitment to responsible technology.
        </motion.p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          {topTeamMembers.map((member: TopTeamMember, index: number) => (
            <motion.div 
              key={index}
              className="bg-[#efedea] p-6 rounded-lg shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="flex flex-col items-center text-center">
                <div className="w-48 h-48 mb-6 flex-shrink-0 overflow-hidden rounded-full">
                  <img 
                    src={member.imageUrl} 
                    alt={member.name} 
                    className={`w-full h-full ${member.imageStyle}`}
                  />
                </div>
                <div>
                  <div className="flex items-center justify-center mb-2">
                    <h3 className="text-2xl font-semibold mr-2" style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}>
                      {member.name}
                    </h3>
                    <div className="flex space-x-2">
                      {member.linkedIn && (
                        <a 
                          href={member.linkedIn} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className="text-[#084248] hover:text-blue-800"
                        >
                          <FaLinkedin size={24} />
                        </a>
                      )}
                      {member.instagram && (
                        <a 
                          href={member.instagram} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className="text-[#084248] hover:text-pink-600"
                        >
                          <FaInstagram size={24} />
                        </a>
                      )}
                    </div>
                  </div>
                  <p 
                    className="text-[#084248] mb-4" 
                    style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
                  >
                    {member.role}
                  </p>
                  <div 
                    className="text-black text-lg space-y-4" 
                    style={{ fontFamily: '"Font1", sans-serif' }}
                  >
                    {member.description.map((paragraph: string, idx: number) => (
                      <p key={idx}>{paragraph}</p>
                    ))}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-1 gap-8 mb-12">
          {bottomTeamMembers.map((member: BottomTeamMember, index: number) => (
            <motion.div 
              key={index}
              className="bg-[#efedea] p-6 rounded-lg shadow-lg flex flex-col items-center text-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 + 0.2 }}
            >
              <div className="flex items-center justify-center mb-2">
                <h3 
                  className="text-xl text-black font-semibold mr-2" 
                  style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
                >
                  {member.name}
                </h3>
                <div className="flex space-x-2">
                  {member.linkedIn && (
                    <a 
                      href={member.linkedIn} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="text-[#084248] hover:text-blue-800"
                    >
                      <FaLinkedin size={20} />
                    </a>
                  )}
                  {member.instagram && (
                    <a 
                      href={member.instagram} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="text-[#084248] hover:text-pink-600"
                    >
                      <FaInstagram size={20} />
                    </a>
                  )}
                </div>
              </div>
              <p 
                className="text-[#084248] mb-2" 
                style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
              >
                {member.role}
              </p>
              <p 
                className="text-black text-lg" 
                style={{ fontFamily: '"Font1", sans-serif' }}
              >
                {member.description}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhoAreWePage;
import React, { useContext, useState, useEffect, ReactNode } from 'react';
import { Amplify } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { CognitoContext } from '../../auth/CognitoProviderWithNavigate';
import awsconfig from '../../aws-exports';
import fibbLogo from '../../components/images/FibbLogoWhite.svg';
import { fetchTokenData } from '../Marketplace/TokenSystem/TokenCounter';
import { ArrowUpRight } from 'lucide-react';

Amplify.configure(awsconfig);

interface DashboardButtonProps {
  title: ReactNode;
  description: string;
  onClick: () => void;
  disabled?: boolean;
  icon: ReactNode;
  fibbCount?: number;
  genTokens?: number;
}

const DashboardButton: React.FC<DashboardButtonProps> = ({ title, description, onClick, disabled = false, icon, fibbCount, genTokens }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.3 }}
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    className={`bg-gradient-to-br from-[#efedea] to-white rounded-lg p-6 flex flex-col h-full justify-between border border-gray-200 shadow-sm transition-all duration-300 ${
      disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer hover:shadow-lg hover:bg-gradient-to-br hover:from-[#d8f3dc] hover:to-[#efedea]'
    }`}
    onClick={disabled ? undefined : onClick}
  >
    <div className="flex flex-col space-y-4">
      <div className="flex items-center space-x-6">
        <div className="p-4 rounded-full transition-all duration-300 group-hover:bg-[#b7e4c7]">
          {icon}
        </div>
        <div className="flex-grow">
          <h2 className="text-2xl sm:text-3xl font-bold mb-2 transition-colors duration-300 group-hover:text-[#285a62]" style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}>{title}</h2>
        </div>
      </div>
      {fibbCount !== undefined && (
        <p className="text-base font-bold text-gray-700">Fibb Tokens: {fibbCount}</p>
      )}
      {genTokens !== undefined && (
        <p className="text-base font-bold text-gray-700">Gen Tokens: {genTokens}</p>
      )}
      <p className="text-base text-gray-600 transition-colors duration-300 group-hover:text-gray-800" style={{ fontFamily: '"Font1", sans-serif' }}>{description}</p>
    </div>
    <div className="flex justify-end mt-4">
      <ArrowUpRight className="text-gray-400 flex-shrink-0 transition-all duration-300 group-hover:text-[#285a62] group-hover:transform group-hover:translate-x-1 group-hover:-translate-y-1" size={24} />
    </div>
  </motion.div>
);

const Spinner: React.FC = () => (
  <div className="flex items-center justify-center h-screen">
    <svg className="animate-spin h-10 w-10 text-[#f79e07]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
    <span className="ml-3 text-xl font-semibold text-[#f79e07]">Loading...</span>
  </div>
);

const CreatePage: React.FC = () => {
  const { isAuthenticated } = useContext(CognitoContext);
  const navigate = useNavigate();
  //Fib ToKEN count
  const [totalFibbCount, setTotalFibbCount] = useState(0);
  // Fib Generation Tokens
  const [genTokens, setGenTokens] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  useEffect(() => {
    const getTokenData = async () => {
      try {
        setIsLoading(true);
        const data = await fetchTokenData();
        setTotalFibbCount(data.fibbs);
        setGenTokens(data.genTokens);
        setError(null);
      } catch (err) {
        setError('Failed to fetch token data');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    getTokenData();
  }, []);
  
  const handleButtonClick = (path: string) => {
    navigate(path);
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex flex-col min-h-screen bg-white text-black">
      <main className="flex flex-col items-center flex-grow p-4 mt-16 sm:mt-32">
        <div className="w-full max-w-4xl space-y-6">
          <div className="text-center mb-12">
            <h1 className="text-4xl sm:text-5xl font-bold mb-4 text-[#004748]" style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}>
              Welcome to Your Fibb Dashboard
            </h1>
            <p className="text-xl text-gray-600" style={{ fontFamily: '"Font1", sans-serif' }}>
              Explore the possibilities and bring your digital self to life with Fibb.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <DashboardButton
              title={<>Create Your <span className="text-[#f79e07]">fibb</span></>}
              description="Go through our guided photo experience so we can learn what you look like. After the training is finished, you'll be able to bring your fibb to life."
              onClick={() => handleButtonClick("/cam")}
              disabled={totalFibbCount < 10}
              icon={<svg className="w-10 h-10 text-[#581a3a]" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" /></svg>}
              fibbCount={totalFibbCount}
            />
            <DashboardButton
              title={<>Bring Your <span className="text-[#f79e07]">fibb</span> to Life</>}
              description="When your fibb is finished, generate yourself wherever you want to be. If you can think it, we'll help you build it."
              onClick={() => handleButtonClick("/image-gen")}
              disabled={genTokens < 15}
              icon={<svg className="w-10 h-10 text-[#ee4036]" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5" /></svg>}
              genTokens={genTokens}
            />
            {/* <DashboardButton
              title={<><span className="text-[#f79e07]">fibb</span> Dating</>}
              description="Experience next-level matchmaking with AI-powered fibb dating. Create high-quality, AI-generated dating profile pictures that showcase your best self, while our advanced technology helps you connect with potential partners based on what truly matters."
              onClick={() => handleButtonClick("/dating-upload")}
              disabled={totalFibbCount < 20}
              icon={<svg className="w-10 h-10 text-[#ff69b4]" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" /></svg>}
              fibbCount={totalFibbCount}
            /> */}
            <DashboardButton
              title={<>Professional <span className="text-[#f79e07]">fibb</span></>}
              description="Elevate your professional presence with a custom fibb tailored for business, networking, and product placement. Generate high-quality AI-powered headshots and polished profiles that make a lasting impact in any professional setting."
              onClick={() => console.log("Accessing fibb Professional")}
              disabled={totalFibbCount < 25}
              icon={<svg className="w-10 h-10 text-[#093f48] " fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /></svg>}
              fibbCount={totalFibbCount}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default CreatePage;
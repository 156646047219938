// src/features/navbar/navbarSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NavbarState {
  isMenuOpen: boolean;
  isProfileDropdownOpen: boolean;
  navItems: Array<{
    name: string;
    path: string;
  }>;
  profileMenuItems: Array<{
    name: string;
    icon: string;
    path: string;
  }>;
}

const initialState: NavbarState = {
  isMenuOpen: false,
  isProfileDropdownOpen: false,
  navItems: [
    { name: "Our Team", path: "/team" },
    { name: "fibbCompute", path: "/compute"},
    { name: "Marketplace", path: "/marketplace" },
    //{ name: "fibbDate", path: "/dating" },
    { name: "Services", path: '/portfolio' },
    { name: "Subscribe", path: "/subscribe" },
  ],
  profileMenuItems: [
    { name: 'Dashboard', icon: 'dashboard', path: '/profile' },
    { name: 'Photo Gallery', icon: 'image', path: '/photo-gallery' },
    { name: 'Billing', icon: 'credit-card', path: 'https://payments.fibb.ai/p/login/bIYbK836g9UA7ew144' },
  ]
};

const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    toggleMenu: (state) => {
      state.isMenuOpen = !state.isMenuOpen;
    },
    closeMenu: (state) => {
      state.isMenuOpen = false;
    },
    toggleProfileDropdown: (state) => {
      state.isProfileDropdownOpen = !state.isProfileDropdownOpen;
    },
    closeProfileDropdown: (state) => {
      state.isProfileDropdownOpen = false;
    },
    updateNavItems: (state, action: PayloadAction<typeof initialState.navItems>) => {
      state.navItems = action.payload;
    },
  },
});

export const {
  toggleMenu,
  closeMenu,
  toggleProfileDropdown,
  closeProfileDropdown,
  updateNavItems,
} = navbarSlice.actions;

export default navbarSlice.reducer;
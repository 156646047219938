import React from 'react';
import { motion } from 'framer-motion';
import fibbLogo from '../../components/images/FibbLogoWhite.svg';
import Footer from '../../components/Footer';

const ComputePage = () => {
  const features = [
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M13 2L3 14h9l-1 8 10-12h-9l1-8z"/>
        </svg>
      ),
      title: "Fastest Training",
      description: "Nearly 10x faster than our closest competitor. Train to 3200+ steps in as low as 7 minutes and 24 seconds."
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z"/>
          <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"/>
          <path d="M12 2v2"/>
          <path d="M12 22v-2"/>
          <path d="m17 20.66-1-1.73"/>
          <path d="M11 10.27 7 3.34"/>
          <path d="m20.66 17-1.73-1"/>
          <path d="m3.34 7 1.73 1"/>
          <path d="M22 12h-2"/>
          <path d="M2 12h2"/>
          <path d="m20.66 7-1.73 1"/>
          <path d="m3.34 17 1.73-1"/>
          <path d="m17 3.34-1 1.73"/>
          <path d="m7 20.66-1-1.73"/>
        </svg>
      ),
      title: "Custom Control",
      description: "Access to more thorough training and advanced control over model training and output."
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
      title: "Monetization",
      description: "Monetize your quality data set and bring in revenue on our marketplace."
    }
  ];

  return (
    <div className="min-h-screen bg-white text-gray-900">
      <main className="max-w-[1200px] mx-auto px-4 sm:px-6 py-12">
        {/* Header */}
        <div className="text-center space-y-6 mb-12">
          <h1 className="text-5xl sm:text-6xl font-bold text-[#004749] mb-4"
          style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
          >
            fibb Compute
          </h1>

          <div className="flex flex-col items-center justify-center space-y-6">
            <div className="text-3xl sm:text-4xl mb-24 font-bold text-[#f79e07]"
            style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
            >
              Coming November 2024
            </div>
          </div>
          
          <div className="space-y-6 max-w-3xl mx-auto mt-16" 
          style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
          >
            <p className="text-4xl sm:text-5xl font-bold text-gray-800">
              Others wait hours,
            </p>
            <p className="text-4xl sm:text-5xl font-bold text-[#004749]">
              We deliver in minutes.
            </p>
          </div>
        </div>

        {/* Features Grid */}
        <div className="mt-32">
          <div className="grid md:grid-cols-3 gap-12">
            {features.map((feature, index) => (
              <div 
                key={index} 
                className="group bg-white rounded-xl border border-gray-200 hover:shadow-xl transition-shadow duration-300 p-8"
              >
                <div className="space-y-6">
                  <div className="rounded-full bg-gray-50 p-4 w-fit group-hover:scale-110 transition-transform duration-300 text-[#f79e07]">
                    {feature.icon}
                  </div>
                  <h3 className="text-2xl font-semibold"
                  style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
                  >{feature.title}</h3>
                  <p className="text-gray-600 text-lg leading-relaxed"
                  style={{ fontFamily: '"Font1", sans-serif' }}
                  >{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
      
    </div>
    
  );
};

export default ComputePage;
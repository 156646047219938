import React from 'react';
import { Linkedin, Instagram } from 'lucide-react';
import { useAppSelector } from '../hooks/redux';
import { SocialLink, FooterLink } from '../store/features/footer/FooterSlice';

const Footer: React.FC = () => {
  const { navigationLinks, socialLinks, copyright, isVisible } = useAppSelector(
    (state) => state.footer
  );

  if (!isVisible) return null;

  const getSocialIcon = (icon: string) => {
    switch (icon) {
      case 'Linkedin':
        return <Linkedin size={20} />;
      case 'Instagram':
        return <Instagram size={20} />;
      default:
        return null;
    }
  };

  return (
    <footer className="bg-transparent py-8" style={{ fontFamily: '"Font1", sans-serif' }}>
      <div className="container mx-auto px-4">
        <div className="mb-8">
          
        </div>
        <div className="flex flex-col items-center md:flex-row md:justify-between md:items-center">
          <div className="order-2 md:order-1 mt-4 md:mt-0">
            <p className="text-gray-600 text-sm">{copyright}</p>
          </div>
          <div className="order-1 md:order-2 flex flex-col md:flex-row items-center md:space-x-6">
            <div className="flex space-x-4 mb-4 md:mb-0">
              {navigationLinks.map((link: FooterLink) => (
                <a
                  key={link.id}
                  href={link.url}
                  className="text-gray-600 hover:text-[#f79302] transition duration-300"
                >
                  {link.text}
                </a>
              ))}
            </div>
            <div className="flex space-x-4">
              {socialLinks.map((link: SocialLink) => (
                <a
                  key={link.id}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`text-gray-600 ${
                    link.platform === 'LinkedIn'
                      ? 'hover:text-blue-600'
                      : 'hover:text-pink-600'
                  } transition-colors`}
                >
                  {getSocialIcon(link.icon)}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
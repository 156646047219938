
import { motion } from 'framer-motion';
import fibbLogo from '../../components/images/FibbLogoWhite.svg';
import marketimg from './marketplacehome.png'

import React from 'react';

const MarketComingSoon = () => {
  const features = [
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="m2 7 4.41-4.41A2 2 0 0 1 7.83 2h8.34a2 2 0 0 1 1.42.59L22 7"/><path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"/>
          <path d="M15 22v-4a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v4"/><path d="M2 7h20"/><path d="M22 7v3a2 2 0 0 1-2 2v0a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 16 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 12 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 8 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 4 12v0a2 2 0 0 1-2-2V7"/>
        </svg>
      ),
      title: "AI Model Marketplace",
      description: "Browse and deploy from thousands of pre-trained models for various applications. Find the perfect solution for your specific needs."
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z"/><path d="m3.3 7 8.7 5 8.7-5"/><path d="M12 22V12"/>
        </svg>
      ),
      title: "Dataset Repository",
      description: "Access high-quality, curated datasets for model training. Enhance your AI models with reliable and diverse data sources."
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"/><circle cx="9" cy="7" r="4"/><path d="M22 21v-2a4 4 0 0 0-3-3.87"/><path d="M16 3.13a4 4 0 0 1 0 7.75"/>
        </svg>
      ),
      title: "Developer Community",
      description: "Join a thriving community of AI developers and enthusiasts. Share insights, collaborate on projects, and grow together."
    }
  ];

  return (
    <div className="min-h-screen bg-white text-gray-900">
      <main className="max-w-[1200px] mx-auto px-4 sm:px-6 py-12">
        {/* Header */}
        <div className="text-center space-y-6 mb-12">
          <h1 className="text-5xl sm:text-6xl font-bold text-[#004749] mb-4"
          style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
          >
            fibb Marketplace
          </h1>

          <div className="flex flex-col items-center justify-center space-y-2">
            <div className="text-3xl sm:text-4xl font-bold text-[#f79e07]"
            style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
            >
              Coming November 2024
            </div>
            
          </div>
          
          <p className="text-xl text-gray-600 max-w-3xl mx-auto mt-8"
          style={{ fontFamily: '"Font1", sans-serif' }}
          >
            Welcome to the future of AI development. Our marketplace brings together the best AI models, 
            datasets, and tools in one seamless platform.
          </p>
        </div>

        {/* Preview Image */}
        <div className="relative mb-16 group">
          <div className="absolute -inset-1 bg-gradient-to-r from-blue-100 to-emerald-100 rounded-xl blur-xl opacity-75 group-hover:opacity-100 transition duration-1000"></div>
          <div className="relative bg-white rounded-xl shadow-2xl p-1">
            <img 
              src={marketimg}
              alt="fibb.ai Marketplace Preview" 
              className="w-full h-auto rounded-lg"
            />
          </div>
        </div>

        {/* Features Grid */}
        <div className="space-y-4">
          
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div 
                key={index} 
                className="group bg-white rounded-xl border border-gray-200 hover:shadow-xl transition-shadow duration-300"
              >
                <div className="p-6 space-y-4">
                  <div className="rounded-full bg-gray-50 p-3 w-fit group-hover:scale-110 transition-transform duration-300 text-[#f79e07]">
                    {feature.icon}
                  </div>
                  <h3 className="text-xl font-semibold"
                  style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
                  >{feature.title}</h3>
                  <p className="text-gray-600"
                  style={{ fontFamily: '"Font1", sans-serif' }}
                  >{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default MarketComingSoon;
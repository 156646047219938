import React from 'react';
import { Plan, formatPrice } from './types';

interface ConsumerSubProps {
  billingPeriod: 'monthly' | 'yearly';
  handleSubscription: (packageId: string, customUrl?: string) => void;
  getCardColor: (category: string, index: number) => string;
  getAbstractShape: (index: number) => JSX.Element;
}

const ConsumerSub: React.FC<ConsumerSubProps> = ({
  billingPeriod,
  handleSubscription,
  getCardColor,
  getAbstractShape,
}) => {
  const consumerPlans: Plan[] = [
    {
      name: 'Fibb Basic',
      price: { monthly: '$12', yearly: '$120' },
      description: 'For casual users',
      features: [
        'No access to Fibbs',
        '30 Generations per month',
        'Basic Support',
        'Additional Generations: $0.50'
      ],
      packageId: { monthly: '2fe526a3-1d2b-4870-bd97-b5fb9365538c', yearly: '5521fb82-7f5e-4106-8646-eb8e08e6a8db' },
    },
    {
      name: 'Fibb Starter',
      price: { monthly: '$27', yearly: '$270' },
      description: 'For beginners',
      features: [
        'All "Fibb Basic" Benefits',
        '1 Fibb per month',
        '50 Generations per month',
        'Higher Priority Support',
        'Additional Fibbs: $15/ea',
        'Additional Generations: $0.50/ea'
      ],
      packageId: { monthly: '19e5c058-9732-4b43-8863-7f4366ca5e02', yearly: '961cd828-f581-47b7-bd15-25b2b61dee7e' },
    },
    {
      name: 'Fibb Standard',
      price: { monthly: '$45', yearly: '$450' },
      description: 'Most popular',
      features: [
        'All "Fibb Starter" Benefits',
        '2 Fibbs per month',
        '100 Generations per month',
        'Higher Priority Support',
        'Premium Content',
        'Additional Fibbs: $10/ea',
        'Additional Generations: $0.30/ea'
      ],
      packageId: { monthly: 'cc2f2542-a7be-42cc-9290-d1f399c6830a', yearly: '51ae3be9-6aaf-4ea7-8e5f-5ea4707fd20f' },
      popular: true,
    },
    {
      name: 'Fibb Pro',
      price: { monthly: '$125', yearly: '$1250' },
      description: 'For power users',
      features: [
        'All "Fibb Standard" Benefits',
        '10 Fibbs per month',
        '500 Generations per month',
        'Priority Support',
        'Dedicated Account Manager',
        'Commercial use license',
        'Additional Fibbs: $5/ea',
        'Additional Generations: $0.20/ea'
      ],
      packageId: { monthly: '2f51a18d-f1e3-4cd1-9b72-ac45c38d85cf', yearly: '1bf43511-9aef-4f94-a0d2-79f3cbca76dc' },
    }
  ];

  return (
    <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
      {consumerPlans.map((plan, index) => (
        <div key={plan.name} className={`relative bg-gradient-to-br ${getCardColor('consumer', index)} rounded-2xl overflow-hidden shadow-lg transform transition-all duration-300 hover:scale-105`}>
          {getAbstractShape(index)}
          <div className="relative z-10 p-8">
            <h3 
              className="text-2xl font-bold mb-2 text-gray-900" 
              style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
            >
              {plan.name}
            </h3>
            <div className="text-5xl font-bold mb-4 text-black"
              style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
            >
              {typeof plan.price === 'string' 
                ? plan.price
                : billingPeriod === 'monthly' 
                  ? plan.price.monthly 
                  : plan.price.yearly}
              <span className="text-lg font-normal text-gray-600">/{billingPeriod === 'monthly' ? 'mo' : 'yr'}</span>
            </div>
            <div className="flex justify-start mb-6">
              <button 
                className="bg-white text-gray-800 py-3 px-8 rounded-full font-bold text-lg shadow-md flex items-center space-x-2 border border-gray-200"
                style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
                onClick={() => handleSubscription(
                  typeof plan.packageId === 'string'
                    ? plan.packageId
                    : billingPeriod === 'monthly'
                      ? plan.packageId.monthly
                      : plan.packageId.yearly,
                  plan.customUrl
                )}
              >
                <span>Subscribe</span>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="h-5 w-5" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </button>
            </div>
            <p 
              className="text-gray-600 mb-6"
              style={{ fontFamily: '"Font1", sans-serif' }}
            >
              {plan.description}
            </p>
            <ul className="space-y-3"
              style={{ fontFamily: '"Font1", sans-serif' }}
            >
              {plan.features.map((feature, index) => (
                <li key={index} className="flex items-start">
                  <svg className="w-5 h-5 mr-2 text-green-500 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="text-gray-700">{feature}</span>
                </li>
              ))}
            </ul>
          </div>
          {plan.popular && (
            <div className="absolute top-2 right-2 bg-gradient-to-r from-blue-500 to-indigo-600 text-white text-xs font-bold py-1.5 px-3 rounded-full shadow-md transform rotate-2 transition-all duration-300 hover:rotate-0 hover:scale-105 hover:shadow-lg">
              Most Popular
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ConsumerSub;
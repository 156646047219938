import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { fetchFalApiKey, generateImageWithFAL } from '../../../pages/CreatePage/ImageGen/components/falAIComponent';
import { fetchBflApiKey, generateImageWithBFL } from '../../../pages/CreatePage/ImageGen/components/bflAIComponent';
import { deductGenToken, fetchTokenData } from '../../../pages/Marketplace/TokenSystem/TokenCounter';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';

interface LoraFile {
  key: string;
  presignedUrl: string;
}

interface FormState {
  selectedStyle: 'Enhanced' | 'Research';
  selectedLora: string;
  subject: string;
}

interface ImageGenState {
  apiKeyFal: string | null;
  apiKeyBfl: string | null;
  errorFal: string | null;
  errorBfl: string | null;
  isLoading: boolean;
  generatedImage: string | null;
  uploadStatus: string | null;
  isSaved: boolean;
  loraFiles: LoraFile[];
  selectedLoraUrl: string;
  genTokens: number;
  error: string | null;
  formState: FormState;
}

const initialState: ImageGenState = {
  apiKeyFal: null,
  apiKeyBfl: null,
  errorFal: null,
  errorBfl: null,
  isLoading: false,
  generatedImage: null,
  uploadStatus: null,
  isSaved: false,
  loraFiles: [],
  selectedLoraUrl: '',
  genTokens: 0,
  error: null,
  formState: {
    selectedStyle: 'Enhanced',
    selectedLora: '',
    subject: '',
  }
};

// Thunks
export const fetchApiKeys = createAsyncThunk(
  'imageGen/fetchApiKeys',
  async () => {
    const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    if (!accessToken) throw new Error('No access token available');
    
    const [falKey, bflKey] = await Promise.all([
      fetchFalApiKey(accessToken),
      fetchBflApiKey(accessToken)
    ]);
    
    return { falKey, bflKey };
  }
);

export const fetchTokens = createAsyncThunk(
  'imageGen/fetchTokens',
  async () => {
    try {
      const data = await fetchTokenData();
      return data.genTokens;
    } catch (error) {
      throw new Error('Failed to fetch token data');
    }
  }
);

export const fetchLoraFiles = createAsyncThunk(
  'imageGen/fetchLoraFiles',
  async () => {
    const { username } = await getCurrentUser();
    const apiUrl = 'https://44stvp2e79.execute-api.us-east-2.amazonaws.com/api/getLoraFiles';
    
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ sub: username }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Lora files');
    }

    const data = await response.json();
    return data.items
      .filter((item: any) => item.status === 'COMPLETED')
      .map((item: any) => ({
        key: item.requestName,
        presignedUrl: item.generatedLoraURL
      }));
  }
);

export const generateImage = createAsyncThunk(
  'imageGen/generateImage',
  async (
    { style, subject, selectedLoraUrl }: { style: 'Enhanced' | 'Research'; subject: string; selectedLoraUrl: string },
    { getState, rejectWithValue }
  ) => {
    const state = getState() as { imageGen: ImageGenState };
    const { apiKeyFal, apiKeyBfl } = state.imageGen;

    try {
      const { userId } = await getCurrentUser();
      const deductionSuccessful = await deductGenToken(userId);
      
      if (!deductionSuccessful) {
        throw new Error('Token deduction failed');
      }

      if (style === 'Enhanced') {
        if (!apiKeyFal) throw new Error('FIBB_ENHANCED key not available');
        return await generateImageWithFAL(apiKeyFal, subject, selectedLoraUrl);
      } else {
        if (!apiKeyBfl) throw new Error('FIBB_RESEARCH key not available');
        return await generateImageWithBFL(apiKeyBfl, subject);
      }
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'Failed to generate image');
    }
  }
);

export const uploadGeneratedImage = createAsyncThunk(
  'imageGen/uploadImage',
  async (generatedImage: string) => {
    const getCurrentTimeStamp = () => {
      return new Date().toISOString().replace(/[-:]/g, "").split('.')[0] + "Z";
    };

    const { userId } = await getCurrentUser();
    const timestamp = getCurrentTimeStamp();
    const fileName = `users/${userId}/gallery/${timestamp}.jpg`;
    
    const imageResponse = await fetch(generatedImage);
    const imageBlob = await imageResponse.blob();
    
    const presignedUrlResponse = await fetch('https://rn3fz2qkeatimhczxdtivhxne40lnkhr.lambda-url.us-east-2.on.aws/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ fileName, fileType: imageBlob.type }),
    });

    if (!presignedUrlResponse.ok) {
      throw new Error(`Failed to get presigned URL`);
    }

    const { uploadUrl } = await presignedUrlResponse.json();
    
    const s3UploadResponse = await fetch(uploadUrl, {
      method: 'PUT',
      body: imageBlob,
      headers: { 'Content-Type': imageBlob.type },
    });

    if (!s3UploadResponse.ok) {
      throw new Error('Failed to upload to S3');
    }

    return fileName;
  }
);

const imageGenSlice = createSlice({
  name: 'imageGen',
  initialState,
  reducers: {
    updateFormState: (state, action: PayloadAction<Partial<FormState>>) => {
      state.formState = { ...state.formState, ...action.payload };
    },
    setSelectedLoraUrl: (state, action: PayloadAction<string>) => {
      state.selectedLoraUrl = action.payload;
    },
    resetState: (state) => {
      state.errorFal = null;
      state.errorBfl = null;
      state.generatedImage = null;
      state.uploadStatus = null;
      state.isSaved = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApiKeys.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchApiKeys.fulfilled, (state, action) => {
        state.apiKeyFal = action.payload.falKey;
        state.apiKeyBfl = action.payload.bflKey;
        state.isLoading = false;
      })
      .addCase(fetchApiKeys.rejected, (state, action) => {
        state.error = action.error.message ?? 'Failed to fetch API keys';
        state.isLoading = false;
      })
      .addCase(fetchTokens.fulfilled, (state, action) => {
        state.genTokens = action.payload;
      })
      .addCase(fetchTokens.rejected, (state) => {
        state.error = 'Failed to fetch token data';
      })
      .addCase(fetchLoraFiles.fulfilled, (state, action) => {
        state.loraFiles = action.payload;
        if (action.payload.length > 0) {
          state.selectedLoraUrl = action.payload[0].presignedUrl;
        }
      })
      .addCase(generateImage.pending, (state) => {
        state.isLoading = true;
        state.errorFal = null;
        state.errorBfl = null;
      })
      .addCase(generateImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.generatedImage = action.payload;
        state.genTokens -= 1;
      })
      .addCase(generateImage.rejected, (state, action) => {
        state.isLoading = false;
        state.errorFal = action.payload as string;
      })
      .addCase(uploadGeneratedImage.pending, (state) => {
        state.uploadStatus = 'Uploading...';
      })
      .addCase(uploadGeneratedImage.fulfilled, (state) => {
        state.uploadStatus = 'Image uploaded successfully';
        state.isSaved = true;
      })
      .addCase(uploadGeneratedImage.rejected, (state, action) => {
        state.uploadStatus = `Upload failed: ${action.error.message}`;
      });
  },
});

export const { updateFormState, setSelectedLoraUrl, resetState } = imageGenSlice.actions;
export default imageGenSlice.reducer;
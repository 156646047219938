// src/App.tsx

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import { Amplify } from 'aws-amplify';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage/HomePage';
import WhoAreWePage from './pages/WhoAreWePage/WhoAreWePage';
import EventsPage from './pages/EventsPage/EventsPage';
import FAQPage from './pages/FAQ/FAQPage';
import CameraPage from './pages/CameraPage/CameraPage';
import ProtectedRoute from './components/ProtectedRoute';
import BlogPage from './pages/Blog/BlogPage';
import PhotoGallery from './pages/PhotoGalleryPage/photoGallery';
import TOSPage from './pages/TOSPage/TOSPage';
import ImageGen from './pages/CreatePage/ImageGen/ImageGen';
import CreatePage from './pages/CreatePage/CreatePage';
import Services from './pages/Services/Services';
import Signup, { checkUser, handleSignOut } from './pages/SignupPage/SignupPage';
import PricingPage from './pages/SubscriptionPage/PricingPage';
import GuidedProcess from './pages/CameraPage/GuidedProcess';       
import ComputePage from './pages/ComputePage/ComputePage';
import CompletionPage from './pages/CameraPage/CompletionPage';
import Profile from './pages/ProfilePage/ProfilePage';        
import MarketplaceSoon from './pages/Marketplace/MarketPlaceTemp';
import TokenDisplay from './pages/Marketplace/TokenSystem/TokenDisplay';
import ConsentPage from './pages/SubscriptionPage/ConsentPage';
import DatingPage from './pages/fibbDate/DatingPage';
import DateLandingPage from './pages/fibbDate/DateLandingPage';

import awsExports from './aws-exports';

Amplify.configure(awsExports);

// Create a wrapper component for the app content to use hooks
const AppContent: React.FC = () => {
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const initUser = async () => {
      const currentUser = await checkUser();
      setUser(currentUser);
    };
    initUser();
  }, []);

  const signOutUser = async () => {
    await handleSignOut();
    setUser(null);
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <NavBar user={user} signOut={signOutUser} />
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/team" element={<WhoAreWePage />} />
          <Route path="/FAQ" element={<FAQPage />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/tokens" element={<TokenDisplay />} />
          <Route path="/subscribe-consumer" element={<PricingPage defaultTab="consumer" />} />
          <Route path="/subscribe-professional" element={<PricingPage defaultTab="professional" />} />
          <Route path="/subscribe-dating" element={<PricingPage defaultTab="dating" />} />
          <Route path="/subscribe" element={<PricingPage defaultTab="consumer" />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/photo-gallery" element={<PhotoGallery />} />
          <Route path="/terms-of-service" element={<TOSPage />} />
          <Route path="/marketplace" element={<MarketplaceSoon />} />
          <Route path="/services" element={<Services />} />
          <Route path="/compute" element={<ComputePage />} />
          <Route path="/consent" element={<ConsentPage />} />
          {/* <Route path="/dating" element={<DateLandingPage />} /> */}
          
          <Route 
            path="/profile" 
            element={
              <ProtectedRoute user={user}>
                <Profile user={user} signOut={signOutUser} />
              </ProtectedRoute>
            } 
          />
          <Route path="/completion" element={<CompletionPage/>} />
          <Route path="/signup" element={<Signup onUserChange={setUser} />} />
          <Route 
            path="/cam" 
            element={
              <ProtectedRoute user={user}>
                <CameraPage />
              </ProtectedRoute>
            } 
          />
          {/* <Route 
            path="/dating-upload" 
            element={
              <ProtectedRoute user={user}>
                <DatingPage />
              </ProtectedRoute>
            } 
          /> */}
          <Route 
            path="/guided" 
            element={
              <ProtectedRoute user={user}>
                <GuidedProcess />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/image-gen" 
            element={
              <ProtectedRoute user={user}>
                <ImageGen />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/get-started" 
            element={
              <ProtectedRoute user={user}>
                <CreatePage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

// Wrap the entire app with Provider and Router
const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <AppContent />
      </Router>
    </Provider>
  );
};

export default App;
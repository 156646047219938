import React, { useState, useCallback, DragEvent, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Amplify } from 'aws-amplify';
import { getCurrentUser } from 'aws-amplify/auth';
import { getCurrentTimeStamp, modelTrain } from '../../utils';
import awsconfig from '../../aws-exports';
import { useLocation } from 'react-router-dom';

import neu_front from './images/solo_shots/neutral_confident_front.webp';
import neu_right from './images/solo_shots/neutral_confident_right.webp';
import neu_left from './images/solo_shots/neutral_confident_left.webp';
import smirk_front from './images/solo_shots/smirk_closed_front.webp';
import smirk_left from './images/solo_shots/smirk_closed_left.webp';
import smirk_right from './images/solo_shots/smirk_closed_right.webp';
import smile_front from './images/solo_shots/smile_laugh_front.webp';
import smile_left from './images/solo_shots/smile_laugh_left.webp';
import smile_right from './images/solo_shots/smile_laugh_right.webp';

import full_body from './images/body_shots/full.webp';
import turned_sideways from './images/body_shots/turned_sideways.webp';
import turned_around from './images/body_shots/turned_around.webp';
import full_body_crossed from './images/body_shots/full_body_crossed.webp';
import sitting_down from './images/body_shots/sitting_down.webp';
import sitting_turned from './images/body_shots/sitting_turned.webp';

Amplify.configure(awsconfig);

interface Card {
  title: string;
  image: string;
  type: 'required' | 'optional';
}

const cards: Card[] = [
  { title: "Neutral Front", image: neu_front, type: 'required' },
  { title: "Neutral Right", image: neu_right, type: 'required' },
  { title: "Neutral Left", image: neu_left, type: 'required' },
  { title: "Smirk Front", image: smirk_front, type: 'required' },
  { title: "Smirk Left", image: smirk_left, type: 'required' },
  { title: "Smirk Right", image: smirk_right, type: 'required' },
  { title: "Smile Front", image: smile_front, type: 'required' },
  { title: "Smile Left", image: smile_left, type: 'required' },
  { title: "Smile Right", image: smile_right, type: 'required' },
  { title: "Full Body", image: full_body, type: 'optional' },
  { title: "Turned Sideways", image: turned_sideways, type: 'optional' },
  { title: "Turned Around", image: turned_around, type: 'optional' },
  { title: "Full Body, Arms Crossed", image: full_body_crossed, type: 'optional' },
  { title: "Sitting Down", image: sitting_down, type: 'optional' },
  { title: "Sitting Turned", image: sitting_turned, type: 'optional' },
];

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      const mobile = window.matchMedia("(max-width: 768px)").matches;
      setIsMobile(mobile);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  return isMobile;
};

const PhotoGallery: React.FC = () => {
  const [userPhotos, setUserPhotos] = useState<{ [key: string]: string }>({});
  const [dragOver, setDragOver] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [submissionName, setSubmissionName] = useState('');
  const [includeFullBody, setIncludeFullBody] = useState(false);
  const isMobile = useIsMobile();
  const { state } = useLocation();

  const requiredCards = cards.filter(card => card.type === 'required');
  const optionalCards = cards.filter(card => card.type === 'optional');

  const handleFileUpload = useCallback((file: File, title: string) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setUserPhotos(prev => ({
        ...prev,
        [title]: reader.result as string
      }));
    };
    reader.readAsDataURL(file);
  }, []);

  const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, title: string) => {
    const file = event.target.files?.[0];
    if (file) {
      handleFileUpload(file, title);
    }
  }, [handleFileUpload]);

  const handleDragOver = useCallback((event: DragEvent<HTMLDivElement>, title: string) => {
    event.preventDefault();
    setDragOver(title);
  }, []);

  const handleDragLeave = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(null);
  }, []);

  const handleDrop = useCallback((event: DragEvent<HTMLDivElement>, title: string) => {
    event.preventDefault();
    setDragOver(null);
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileUpload(file, title);
    }
  }, [handleFileUpload]);

  const handleRemove = useCallback((title: string) => {
    setUserPhotos(prev => {
      const newPhotos = { ...prev };
      delete newPhotos[title];
      return newPhotos;
    });
  }, []);

  const isAllRequiredPhotosUploaded = useCallback(() => {
    return requiredCards.every(card => userPhotos[card.title]);
  }, [userPhotos, requiredCards]);

  const handleUpload = useCallback(async () => {
    if (!isAllRequiredPhotosUploaded()) {
      alert('Please upload all required facial photos before submitting.');
      return;
    }

    if (!submissionName.trim()) {
      alert('Please enter a name or label for your submission.');
      return;
    }

    setIsUploading(true);

    try {
      const { userId } = await getCurrentUser();
      const startingTimestamp = getCurrentTimeStamp();
      const prefix = includeFullBody ? 'fullbody' : 'nullbody';

      const relevantCards = includeFullBody ? [...requiredCards, ...optionalCards] : requiredCards;

      const uploadPromises = relevantCards.map(async (card) => {
        if (!userPhotos[card.title]) return null;
        
        const photoData = userPhotos[card.title];
        const fileName = `users/${userId}/photos/${prefix}_${submissionName}/${card.title}.jpg`;
        
        const response = await fetch(photoData);
        const blob = await response.blob();

        const presignedUrlResponse = await fetch('https://rn3fz2qkeatimhczxdtivhxne40lnkhr.lambda-url.us-east-2.on.aws/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ fileName, fileType: blob.type }),
        });

        if (!presignedUrlResponse.ok) {
          throw new Error(`Failed to get presigned URL for ${fileName}`);
        }

        const { uploadUrl } = await presignedUrlResponse.json();

        const s3UploadResponse = await fetch(uploadUrl, {
          method: 'PUT',
          body: blob,
          headers: { 'Content-Type': blob.type },
        });

        if (!s3UploadResponse.ok) {
          throw new Error(`Failed to upload ${fileName}`);
        }

        return fileName;
      });

      const results = await Promise.all(uploadPromises);
      console.log('All photos uploaded:', results);
      alert('All photos have been uploaded successfully!');

      await modelTrain(userId, submissionName, 3500, submissionName);
      
      window.location.href = '/completion';
    } catch (error) {
      console.error('Error uploading photos:', error);
      alert('Failed to upload photos. Please try again.');
    } finally {
      setIsUploading(false);
    }
  }, [userPhotos, isAllRequiredPhotosUploaded, submissionName, includeFullBody, requiredCards, optionalCards]);

  const renderCard = (card: Card, index: number) => (
    <div
      key={index}
      className="bg-[#144a53] rounded-lg overflow-hidden"
    >
      <h3 className="text-xl font-bold p-4 bg-[#0c3942]" style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}>
        {card.title}
      </h3>
      <div className="p-4 flex flex-col space-y-4">
        <div className="w-full h-64 flex items-center justify-center bg-[#0c3942] rounded-lg overflow-hidden">
          <img 
            src={card.image} 
            alt={card.title} 
            className="max-w-full max-h-full object-contain"
          />
        </div>
        <div 
          className="w-full h-64 bg-[#0c3942] rounded-lg flex items-center justify-center relative overflow-hidden"
          onDragOver={(e) => handleDragOver(e, card.title)}
          onDragLeave={handleDragLeave}
          onDrop={(e) => handleDrop(e, card.title)}
        >
          {userPhotos[card.title] ? (
            <div className="relative w-full h-full flex items-center justify-center">
              <img 
                src={userPhotos[card.title]} 
                alt={`User ${card.title}`} 
                className="max-w-full max-h-full object-contain"
              />
              <button
                onClick={() => handleRemove(card.title)}
                className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-2 hover:bg-red-600 transition-colors duration-300"
                aria-label="Remove photo"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
          ) : (
            <label 
              htmlFor={`photo-upload-${index}`} 
              className={`cursor-pointer flex flex-col items-center justify-center w-full h-full ${dragOver === card.title ? 'bg-[#1c5a64]' : ''}`}
            >
              <svg className="h-12 w-12 text-gray-400 mb-2" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <p className="text-sm text-gray-300">Click to upload or drag and drop</p>
            </label>
          )}
        </div>
        <input
          id={`photo-upload-${index}`}
          type="file"
          accept="image/*"
          onChange={(e) => handleFileChange(e, card.title)}
          className="hidden"
        />
        {isMobile && (
          <input
            id={`camera-upload-${index}`}
            type="file"
            accept="image/*"
            capture="user"
            onChange={(e) => handleFileChange(e, card.title)}
            className="hidden"
          />
        )}
        <div className="flex space-x-2">
          <button
            onClick={() => document.getElementById(`photo-upload-${index}`)?.click()}
            className="flex-1 bg-[#f79302] text-black font-bold py-2 px-4 rounded-lg text-sm hover:bg-[#f79600] transition-all duration-300"
            style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
          >
            Choose File
          </button>
          {isMobile && (
            <button
              onClick={() => document.getElementById(`camera-upload-${index}`)?.click()}
              className="flex-1 bg-[#cbf59a] text-black font-bold py-2 px-4 rounded-lg text-sm hover:bg-[#b8e089] transition-all duration-300"
              style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
            >
              Take Selfie
            </button>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-r from-[#093f48] to-[#004948] text-white">
      <main className="flex flex-col items-center flex-grow p-4 sm:p-6 pb-16 sm:pb-6">
        <h1
          className="text-4xl sm:text-5xl font-bold mb-8 sm:mb-16 mt-8 sm:mt-16 text-center"
          style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
        >
          Your <span className="text-[#cbf59a]">fibb</span> Guided Process
        </h1>
        
        <p className="text-center text-lg mb-8 sm:mb-16 max-w-2xl">
          Embark on your personalized fibb journey! Upload your photos below to kickstart your transformative experience.
        </p>

        <div className="w-full max-w-md mb-8">
          <div className="flex flex-col space-y-4">
            <div>
              <label htmlFor="submissionName" className="block text-sm font-medium text-white mb-2">
                Submission Name (required)
              </label>
              <input
                type="text"
                id="submissionName"
                value={submissionName}
                onChange={(e) => setSubmissionName(e.target.value)}
                className="w-full px-3 py-2 bg-[#144a53] text-white rounded-md focus:outline-none focus:ring-2 focus:ring-[#cbf59a]"
                placeholder="Enter a name for your submission"
                required
              />
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="includeFullBody"
                checked={includeFullBody}
                onChange={(e) => setIncludeFullBody(e.target.checked)}
                className="w-4 h-4 text-[#cbf59a] bg-[#144a53] border-[#144a53] rounded focus:ring-[#cbf59a]"
              />
              <label htmlFor="includeFullBody" className="text-sm text-white">
                Include full body photos (recommended for optimal results)
              </label>
            </div>
          </div>
        </div>

        {/* Required Photos Section */}
        <h2 className="text-2xl font-bold mb-4 text-center">Required Photos</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 sm:gap-8 w-full max-w-6xl mb-8 sm:mb-16">
          {requiredCards.map((card, index) => renderCard(card, index))}
        </div>

        {/* Optional Photos Section */}
        {includeFullBody && (
          <>
            <h2 className="text-2xl font-bold mb-4 text-center">Full Body Photos (Optional)</h2>
            <p className="text-center text-lg mb-8 max-w-2xl">
              For the most optimal results, please include full body photos. These additional poses will help create a more comprehensive and accurate representation.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 sm:gap-8 w-full max-w-6xl mb-8 sm:mb-16">
              {optionalCards.map((card, index) => renderCard(card, index + requiredCards.length))}
            </div>
          </>
        )}

        <button
          onClick={handleUpload}
          disabled={!isAllRequiredPhotosUploaded() || isUploading || !submissionName.trim()}
          className={`mt-8 px-8 py-3 text-lg font-bold rounded-lg transition-all duration-300 ${
            isAllRequiredPhotosUploaded() && !isUploading && submissionName.trim()
              ? 'bg-[#cbf59a] text-black hover:bg-[#b8e089]'
              : 'bg-gray-400 text-gray-700 cursor-not-allowed'
          }`}
          style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
        >
          {isUploading ? 'Uploading...' : 'Upload Photos'}
        </button>
      </main>
    </div>
  );
};

export default PhotoGallery;
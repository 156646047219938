// src/store/features/footer/footerSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FooterLink {
  id: string;
  text: string;
  url: string;
}

export interface SocialLink {
  id: string;
  platform: 'LinkedIn' | 'Instagram';
  url: string;
  icon: 'Linkedin' | 'Instagram';
}

export interface FooterState {
  navigationLinks: FooterLink[];
  socialLinks: SocialLink[];
  copyright: string;
  isVisible: boolean;
}

const initialState: FooterState = {
  navigationLinks: [
    { id: 'faq', text: 'FAQ', url: '/faq' },
    { id: 'tos', text: 'Terms of Service', url: '/terms-of-service' },
    { id: 'events', text: 'Events', url: '/events' },
    { id: 'blog', text: 'Blog', url: '/blog' }
  ],
  socialLinks: [
    {
      id: 'linkedin',
      platform: 'LinkedIn',
      url: 'https://www.linkedin.com/company/fibb-ai',
      icon: 'Linkedin'
    },
    {
      id: 'instagram',
      platform: 'Instagram',
      url: 'https://www.instagram.com/fibb.ai',
      icon: 'Instagram'
    }
  ],
  copyright: '© 2024 fibb.ai. All rights reserved.',
  isVisible: true
};

export const footerSlice = createSlice({
  name: 'footer',
  initialState,
  reducers: {
    updateNavigationLinks: (state, action: PayloadAction<FooterLink[]>) => {
      state.navigationLinks = action.payload;
    },
    updateSocialLinks: (state, action: PayloadAction<SocialLink[]>) => {
      state.socialLinks = action.payload;
    },
    updateCopyright: (state, action: PayloadAction<string>) => {
      state.copyright = action.payload;
    },
    toggleVisibility: (state, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload;
    }
  }
});

export const {
  updateNavigationLinks,
  updateSocialLinks,
  updateCopyright,
  toggleVisibility
} = footerSlice.actions;

export default footerSlice.reducer;
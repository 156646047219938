export interface PlanPrice {
    monthly: string;
    yearly: string;
  }
  
  export interface Plan {
    name: string;
    price: PlanPrice | string;
    description: string;
    features: string[];
    packageId: { monthly: string, yearly: string } | string;
    popular?: boolean;
    customUrl?: string;
  }
  
  export type PlanCategory = 'consumer' | 'professional' | 'dating';
  
  // Add helper function to handle price display
  export const formatPrice = (price: PlanPrice | string, billingPeriod: 'monthly' | 'yearly'): string => {
    if (typeof price === 'string') {
      return price;
    }
    return billingPeriod === 'monthly' ? price.monthly : price.yearly;
  };
import { configureStore } from '@reduxjs/toolkit';
import footerReducer from './features/footer/FooterSlice';
import authReducer from './features/auth/authSlice';
import navbarReducer from './features/navbar/navbarSlice';
import uiReducer from './features/ui/uiSlice';
import teamReducer from './features/ourTeam/teamSlice';
import galleryReducer from './features/gallery/gallerySlice';
import pricingReducer from './features/pricing/pricingSlice';
import imageGenReducer from './features/imagegen/imageGenSlice';

export const store = configureStore({
  reducer: {
    footer: footerReducer,
    auth: authReducer,
    navbar: navbarReducer,
    ui: uiReducer,
    team: teamReducer,
    gallery: galleryReducer, 
    pricing: pricingReducer,
    imageGen: imageGenReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
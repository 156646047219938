import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentUser } from 'aws-amplify/auth';
import axios from 'axios';
import type { RootState } from '../../index';
import type { Plan, PlanCategory } from './type';
import { consumerPlans, professionalPlans, datingPlans } from './planData';

interface PricingState {
    activeTab: PlanCategory;
    billingPeriod: 'monthly' | 'yearly';
    checkoutUrl: string | null;
    isAuthenticated: boolean;
    loading: boolean;
    error: string | null;
  }
  
  const initialState: PricingState = {
    activeTab: 'consumer',
    billingPeriod: 'monthly',
    checkoutUrl: null,
    isAuthenticated: false,
    loading: false,
    error: null
  };
  
  // Async thunks
  export const initiateStripeCheckout = createAsyncThunk(
    'pricing/initiateStripeCheckout',
    async (packageId: string, { rejectWithValue }) => {
      try {
        const { userId } = await getCurrentUser();
        console.log('Sending request with:', { userId, packageId });
  
        const response = await axios.post(
          'https://o1t89jiych.execute-api.us-east-2.amazonaws.com/api/fibb-stripe-checkout-consumer',
          { userId, packageId },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
  
        if (response.data.statusCode === 400) {
          throw new Error(`Server error: ${response.data.body}`);
        }
  
        return response.data;
      } catch (error) {
        console.log('Error initiating Stripe checkout:', error);
        return rejectWithValue(error instanceof Error ? error.message : 'Failed to initiate checkout');
      }
    }
  );
  
  export const checkAuthStatus = createAsyncThunk(
    'pricing/checkAuthStatus',
    async () => {
      try {
        await getCurrentUser();
        return true;
      } catch (error) {
        return false;
      }
    }
  );
  
  const pricingSlice = createSlice({
    name: 'pricing',
    initialState,
    reducers: {
      setActiveTab: (state, action) => {
        state.activeTab = action.payload;
      },
      setBillingPeriod: (state, action) => {
        state.billingPeriod = action.payload;
      },
      clearCheckoutUrl: (state) => {
        state.checkoutUrl = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(checkAuthStatus.fulfilled, (state, action) => {
          state.isAuthenticated = action.payload;
        })
        .addCase(initiateStripeCheckout.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(initiateStripeCheckout.fulfilled, (state, action) => {
          state.loading = false;
          state.checkoutUrl = action.payload.checkoutUrl;
        })
        .addCase(initiateStripeCheckout.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload as string;
        });
    },
  });
  
  // Selectors
  export const selectPricingState = (state: RootState) => state.pricing;
  export const selectActiveTab = (state: RootState) => state.pricing.activeTab;
  export const selectBillingPeriod = (state: RootState) => state.pricing.billingPeriod;
  export const selectIsAuthenticated = (state: RootState) => state.pricing.isAuthenticated;
  export const selectCheckoutUrl = (state: RootState) => state.pricing.checkoutUrl;
  export const selectLoading = (state: RootState) => state.pricing.loading;
  export const selectError = (state: RootState) => state.pricing.error;
  
  export const { setActiveTab, setBillingPeriod, clearCheckoutUrl } = pricingSlice.actions;
  export default pricingSlice.reducer;
import React from 'react';
import { Plan, formatPrice } from './types';

interface ProfessionalSubProps {
  handleSubscription: (packageId: string, customUrl?: string) => void;
  getCardColor: (category: string, index: number) => string;
  getAbstractShape: (index: number) => JSX.Element;
}

const ProfessionalSub: React.FC<ProfessionalSubProps> = ({
  handleSubscription,
  getCardColor,
  getAbstractShape,
}) => {
  const professionalPlans: Plan[] = [
    {
      name: 'Launch',
      description: 'Provide your own professional portfolio.',
      price: '$1,250',
      features: [
        'Handcrafted Fibb',
        '100 Curated Headshots',
        '10 Clothing, Expression, and Background changes'
      ],
      packageId: { monthly: 'launch-monthly', yearly: 'launch-yearly' },
    },
    {
      name: 'Ascent',
      description: "We'll fly a photographer to you and coach you through a photoshoot in your city.",
      price: '$2,700',
      features: [
        '4-5 hour photoshoot',
        'All edits from your shoot',
        'Handcrafted Fibb',
        'Corporate Headshot Package',
        'Creative Headshot Package'
      ],
      packageId: { monthly: 'ascent-monthly', yearly: 'ascent-yearly' },
    },
    {
      name: 'Summit',
      description: 'Ascent + Lifestyle',
      price: '$3,250',
      features: [
        '4-5 hour photoshoot',
        'All edits from your shoot',
        'Handcrafted Fibb',
        'Corporate Headshot Package',
        'Creative Headshot Package',
        'Lifestyle Package'
      ],
      packageId: { monthly: 'summit-monthly', yearly: 'summit-yearly' },
    },
    {
      name: 'Pinnacle',
      description: 'A Fibb curated experience',
      price: '$5,000',
      features: [
        '8-10 hour photoshoot',
        'All edits from your shoot',
        'Handcrafted Fibb',
        'Corporate Headshot Package',
        'Creative Headshot Package',
        'Lifestyle Package',
        'Mega-Prompt Package',
        'Creative General Package'
      ],
      packageId: { monthly: 'pinnacle-monthly', yearly: 'pinnacle-yearly' },
    }
  ];

  return (
    <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
      {professionalPlans.map((plan, index) => (
        <div key={plan.name} className={`relative bg-gradient-to-br ${getCardColor('professional', index)} rounded-2xl overflow-hidden shadow-lg transform transition-all duration-300 hover:scale-105`}>
          {getAbstractShape(index)}
          <div className="relative z-10 p-8">
            <h3 
              className="text-2xl font-bold mb-2 text-gray-900" 
              style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
            >
              {plan.name}
            </h3>
            <div className="text-5xl font-bold mb-4 text-black"
              style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
            >
              {formatPrice(plan.price, 'monthly')}
            </div>
            <div className="flex justify-start mb-6">
              <button 
                className="bg-white text-gray-800 py-3 px-8 rounded-full font-bold text-lg shadow-md flex items-center space-x-2 border border-gray-200"
                style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
                onClick={() => handleSubscription(
                  typeof plan.packageId === 'string'
                    ? plan.packageId
                    : plan.packageId.monthly,
                  plan.customUrl
                )}
              >
                <span>Subscribe</span>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="h-5 w-5" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </button>
            </div>
            <p 
              className="text-gray-600 mb-6"
              style={{ fontFamily: '"Font1", sans-serif' }}
            >
              {plan.description}
            </p>
            <ul className="space-y-3"
              style={{ fontFamily: '"Font1", sans-serif' }}
            >
              {plan.features.map((feature, index) => (
                <li key={index} className="flex items-start">
                  <svg className="w-5 h-5 mr-2 text-green-500 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="text-gray-700">{feature}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProfessionalSub;
// src/pages/PricingPage/PricingPage.tsx

import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  setActiveTab,
  setBillingPeriod,
  initiateStripeCheckout,
  checkAuthStatus,
  selectActiveTab,
  selectBillingPeriod,
  selectIsAuthenticated,
  selectCheckoutUrl,
  selectError,
  clearCheckoutUrl
} from '../../store/features/pricing/pricingSlice';
import ConsumerSub from './components/ConsumerSub';
import ProfessionalSub from './components/ProfessionalSub';
// import DatingSub from './components/DatingSub';
import type { PlanCategory } from '../../store/features/pricing/type';

interface PricingPageProps {
  defaultTab?: PlanCategory;
}

const PricingPage: React.FC<PricingPageProps> = ({ defaultTab = 'consumer' }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const activeTab = useAppSelector(selectActiveTab);
  const billingPeriod = useAppSelector(selectBillingPeriod);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const checkoutUrl = useAppSelector(selectCheckoutUrl);
  const error = useAppSelector(selectError);

  useEffect(() => {
    dispatch(setActiveTab(defaultTab));
  }, [defaultTab, dispatch]);

  useEffect(() => {
    dispatch(checkAuthStatus());
  }, [dispatch]);

  useEffect(() => {
    if (checkoutUrl) {
      window.open(checkoutUrl);
      dispatch(clearCheckoutUrl());
    }
  }, [checkoutUrl, dispatch]);

  const handleSubscription = useCallback(async (packageId: string, customUrl?: string) => {
    if (customUrl) {
      window.open(customUrl, '_blank');
      return;
    }

    if (!packageId) {
      throw new Error('Invalid packageId');
    }
  
    if (!isAuthenticated) {
      navigate("/signup");
      return;
    }
  
    try {
      await dispatch(initiateStripeCheckout(packageId)).unwrap();
    } catch (error) {
      console.log('Error during subscription process:', error);
    }
  }, [dispatch, isAuthenticated, navigate]);

  const getCardColor = useCallback((category: string, index: number): string => {
    switch (category) {
      case 'consumer':
        return index === 0 ? 'from-orange-100 to-orange-200' :
               index === 1 ? 'from-purple-100 to-purple-200' :
               index === 2 ? 'from-blue-100 to-blue-200' :
               'from-green-100 to-green-200';
      case 'professional':
        return index % 2 === 0 ? 'from-teal-100 to-teal-200' : 'from-indigo-100 to-indigo-200';
      // case 'dating':
      //   return 'from-pink-100 to-pink-200';
      default:
        return 'from-gray-100 to-gray-200';
    }
  }, []);

  const getAbstractShape = useCallback((index: number): JSX.Element => {
    return (
      <svg key={`shape-${index}`} className="absolute inset-0 w-full h-full" viewBox="0 0 400 300" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path d="M0 100 C 80 30, 130 30, 200 100 S 300 170, 400 100" stroke="rgba(128,128,128,0.2)" strokeWidth="2" fill="none" />
        <circle cx="340" cy="60" r="100" fill="rgba(128,128,128,0.1)" />
        <circle cx="50" cy="250" r="70" fill="rgba(128,128,128,0.05)" />
        <path d="M0 200 C 80 130, 130 130, 200 200 S 300 270, 400 200" stroke="rgba(128,128,128,0.1)" strokeWidth="1.5" fill="none" />
        <circle cx="150" cy="150" r="40" fill="rgba(128,128,128,0.07)" />
      </svg>
    );
  }, []);

  return (
    <div className="bg-white min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl sm:text-5xl font-extrabold text-center text-[#004849] mb-4"
          style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
        >Choose Your Perfect Plan</h1>
        <p className="text-xl sm:text-2xl text-center mb-12 text-gray-600"
          style={{ fontFamily: '"Font1", sans-serif' }}
        >Tailored solutions to elevate your experience</p>
        
        {error && (
          <div className="mb-6 text-center text-red-600">
            {error}
          </div>
        )}
        
        <div className="flex flex-wrap justify-center gap-4 mb-12">
          {(['consumer', 'professional'/*, 'dating'*/] as PlanCategory[]).map((tab) => (
            <button
              key={tab}
              className={`px-6 py-3 rounded-full font-semibold text-lg transition-all duration-300 ease-in-out min-w-[180px] flex items-center justify-center
                ${activeTab === tab
                  ? 'bg-[#004849] text-white shadow-lg transform scale-105' 
                  : 'bg-[#efedea] text-gray-700 hover:bg-gray-100'
                }`}
              style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
              onClick={() => dispatch(setActiveTab(tab))}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>

        {activeTab === 'consumer' && (
          <div className="flex justify-center mb-12">
            <div className="bg-[#efedea] p-1 rounded-full inline-flex items-center shadow-md">
              <button
                className={`px-6 py-2 rounded-full text-sm font-medium transition-colors duration-300 ease-in-out
                  ${billingPeriod === 'monthly' ? 'bg-[#004748] text-white' : 'text-gray-700'}`}
                onClick={() => dispatch(setBillingPeriod('monthly'))}
                style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
              >
                Monthly
              </button>
              <button
                className={`px-6 py-2 rounded-full text-sm font-medium transition-colors duration-300 ease-in-out
                  ${billingPeriod === 'yearly' ? 'bg-[#004748] text-white' : 'text-gray-700'}`}
                onClick={() => dispatch(setBillingPeriod('yearly'))}
                style={{ fontFamily: '"Sofia Pro Bold", sans-serif' }}
              >
                Yearly (Save 10%)
              </button>
            </div>
          </div>
        )}

        {activeTab === 'professional' && (
          <div className="text-center mb-12">
            <p className="text-lg text-gray-700 max-w-3xl mx-auto" style={{ fontFamily: '"Font1", sans-serif' }}>
              Transform your professional image with our comprehensive photoshoot packages. 
              From curated headshots to full lifestyle sessions, we'll help you create the perfect visual narrative for your career.
            </p>
          </div>
        )}

        {/* {activeTab === 'dating' && (
          <div className="text-center mb-12">
            <p className="text-lg text-gray-700 max-w-3xl mx-auto" style={{ fontFamily: '"Font1", sans-serif' }}>
              Elevate your dating profile with our expert photography and profile enhancement services. 
              Let us help you make that perfect first impression and stand out in the digital dating world.
            </p>
          </div>
        )} */}

        {activeTab === 'consumer' && (
          <ConsumerSub 
            billingPeriod={billingPeriod}
            handleSubscription={handleSubscription}
            getCardColor={getCardColor}
            getAbstractShape={getAbstractShape}
          />
        )}

        {activeTab === 'professional' && (
          <ProfessionalSub 
            handleSubscription={handleSubscription}
            getCardColor={getCardColor}
            getAbstractShape={getAbstractShape}
          />
        )}

        {/* {activeTab === 'dating' && (
          <DatingSub 
            handleSubscription={handleSubscription}
            getCardColor={getCardColor}
            getAbstractShape={getAbstractShape}
          />
        )} */}
      </div>
    </div>
  );
};

export default PricingPage;
// src/features/ui/uiSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UIState {
  isNavbarOpen: boolean;
  currentPage: string;
  theme: 'light' | 'dark';
}

const initialState: UIState = {
  isNavbarOpen: false,
  currentPage: 'home',
  theme: 'light',
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleNavbar: (state) => {
      state.isNavbarOpen = !state.isNavbarOpen;
    },
    setCurrentPage: (state, action: PayloadAction<string>) => {
      state.currentPage = action.payload;
    },
    setTheme: (state, action: PayloadAction<'light' | 'dark'>) => {
      state.theme = action.payload;
    },
  },
});

export const { toggleNavbar, setCurrentPage, setTheme } = uiSlice.actions;
export default uiSlice.reducer;